<script setup lang="ts">
defineProps({
  "as": { default: 'div' },
  "active": { type: Boolean,  }
})
const el = ref()

watch(() => __props.active, (active) => {
  const _el = unrefElement(el)

  if (active && _el)
    _el.scrollIntoView({ block: 'nearest', inline: 'start' })
})
</script>

<template>
  <component :is="as" ref="el">
    <slot />
  </component>
</template>
